const homeRouter = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index.vue"),
    meta: { title: "首页", keepAlive: false },
  },
  {
    path: "/houseDetail",
    name: "houseDetail",
    component: () => import("@/views/Mobile/houseDetail.vue"),
    meta: { title: "房源详情", keepAlive: false },
  },

  {
    path: "/Editor",
    name: "Editor",
    component: () => import("@/views/Mobile/editor.vue"),
    meta: { title: "合同在线编辑", keepAlive: false },
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/views/Mobile/sign.vue"),
    meta: { title: "签约", keepAlive: false },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
    meta: { title: "用户登录", keepAlive: false },
  },
  {
    path: "/layouts",
    name: "layouts",
    component: () => import("@/views/layouts/index.vue"),
    redirect: "/layouts/list",
    meta: { title: "首页", keepAlive: false },
    children:[
      {
        path: "list",
        name: "list",
        component: () => import("@/views/list/index.vue"),
        meta: { title: "列表", keepAlive: false },
      },
      {
        path: "addlist",
        name: "addlist",
        component: () => import("@/views/addlist/index.vue"),
        meta: { title: "新增编辑", keepAlive: false },
      },
      {
        path: "contract",
        name: "contract",
        component: () => import("@/views/contract/index.vue"),
        meta: { title: "签约合同列表", keepAlive: false },
      },
      {
        path: "matchinglist",
        name: "matchinglist",
        component: () => import("@/views/matchinglist/index.vue"),
        meta: { title: "顾问匹配申请列表", keepAlive: false },
      },
      {
        path: "matchingform",
        name: "matchingform",
        component: () => import("@/views/matchingform/index.vue"),
        meta: { title: "匹配申请", keepAlive: false },
      },
    ]
  },
]
export default homeRouter