import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store' //vuex
import ElementUI from 'element-ui'
import "./assets/css/element/index.css"//自定义主题
import "./assets/css/mixin.scss"//自定义主题
import VueAwesomeSwiper from 'vue-awesome-swiper'//Swiper
import 'swiper/swiper-bundle.css'//Swiper  Css
import 'normalize.css/normalize.css'// 清楚默认样式
import './assets/css/reset.css'
import cookies from '@/utils/cookie'
import htmlToPdf from '@/utils/htmlToPdf'
import vueEsign from 'vue-esign' //签名插件
import VueClipboard from 'vue-clipboard2' //复制
import '@/utils/vant'
Vue.use(VueClipboard)
Vue.use(vueEsign);
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf)
Vue.config.productionTip = false
Vue.prototype.$cookie = cookies;
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
// import style (>= Swiper 6.x)
Vue.filter('empty', function (value) {
  if (!value || value == null || value == '' || value == '空') return '-'
  return value
})
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
