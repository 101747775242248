<template>
  <div id="app">
    <router-view>
    </router-view>
  </div>
</template>
<script>

export default {
  name: "App",
  data() {
    return {
      list: [],
    };
  },
 
};
</script>

<style>

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}
@media screen and (max-width: 500px) {
    .el-message {
      min-width: 300px !important;
    }
}
</style>
