import Vue from 'vue'
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);
import { Button } from 'vant';
import { DatetimePicker  } from 'vant';
import { Field   } from 'vant';
import { Calendar    } from 'vant';
import { Popup } from 'vant';
import { Toast } from 'vant';
Vue.use(Button)
.use(Popup)
.use(DatetimePicker)
.use(Field)
.use(Calendar)
.use(Toast)