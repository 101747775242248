import Vue from "vue";
import VueRouter from "vue-router";
//页面路由使用模块引入方式
import layouts from './modul/layouts'
import cookie from '@/utils/cookie'
import { Message } from 'element-ui';
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/layouts",
    },
    ...layouts
  ],
});

// 路由守卫
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title; // 设置title名
  if(to.path=='/houseDetail'||to.path=='/login'||to.path=='/sign'){
    console.log('不验证登录')
    next()
  }else{
    console.log('验证登录')
    if(cookie.get('token')){
      // 判断权限 1 超级管理员 2 顾问
      if(cookie.get('status') == 2){
        // 解决跳转二级路由 无限循环问题处理
        if(to.fullPath === '/layouts/matchingform'){
          next()
        }else{
          next('/layouts/matchingform')
        }
      }else{
        next()
      }
    }else{
      next('/login')
      Message.warning('登录失效，请重新登录！')
    }
  }
  
});

export default router;
